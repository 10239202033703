/* You can add global styles to this file, and also import other style files */

body {
  background-color: #faf3f8;
}

.node-content-wrapper, .tree-children {
  position: relative;
}

.node-content-wrapper::before, .tree-children::after {
  content: "";
  position: absolute;
}

.node-content-wrapper::before {
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  height: 28px;
  top: -17px;
  width: 20px;
  left: -28px;
}

.tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
  width: 25px;
}

.tree-children::after {
  border-left: 1px solid lightgrey;
  height: 100%;
  top: -15px;
  left: -15px;
}

treenode:last-child > .tree-node > .tree-children::after {
  border-left: none;
}

.toggle-children {
  z-index: 1;
}

